import { render, staticRenderFns } from "./ChannelPassport.vue?vue&type=template&id=2ecb6aa6&scoped=true"
import script from "./ChannelPassport.vue?vue&type=script&lang=js"
export * from "./ChannelPassport.vue?vue&type=script&lang=js"
import style0 from "./ChannelPassport.vue?vue&type=style&index=0&id=2ecb6aa6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ecb6aa6",
  null
  
)

export default component.exports