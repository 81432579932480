<template>
  <v-card width="350">
    <v-col align="center">
      <h3 class="mt-5 mb-2" align="center">Паспорт канала</h3>

      <v-row v-if="properties">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">ID объекта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ properties.id }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Тип канала</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_channeltype"
            v-model="channel_type_id"
            item-text="name"
            item-value="id"
            placeholder="Тип канала"
            outlined
            dense
            hide-details
            class="mb-1"
            clearable
          />
        </v-col>
      </v-row>

      <v-row v-if="properties">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">ID канала</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ channel_id }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Пропускной пункт</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="checkpoint"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Название канала</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="name"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Источник питания</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="water_source"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Назначение</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="purpose"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Укрепление</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_strengtheningtype"
            v-model="strengthening_type_id"
            item-text="name"
            item-value="id"
            placeholder="Укрепление"
            outlined
            dense
            hide-details
            class="mb-1"
            clearable
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Благоустроен</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_landscaping"
            v-model="landscaping_id"
            item-text="name"
            item-value="id"
            placeholder="Тип канала"
            outlined
            dense
            hide-details
            class="mb-1"
            clearable
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Задвижки</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="valves"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Шандоры</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="shandors"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Мосты</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="bridges"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Дюкеры</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="duckers"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Принадлежность сооружения</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="passport_belonging"
            v-model="belonging_id"
            item-text="name"
            item-value="id"
            placeholder="Принадлежность сооружения"
            outlined
            dense
            hide-details
            class="mb-1"
            clearable
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Введен в эксплуатацию</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu v-model="plantingDateMenu">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="exploitation_year"
                v-bind="attrs"
                v-on="on"
                hide-details
                clearable
              ></v-text-field>
            </template>
            <v-date-picker-years min="1960" max="2024" v-model="exploitation_year" scrollable></v-date-picker-years>
          </v-menu>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Пропускная способность (м3 в сек.)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="bandwidth"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Длина всего канала</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="total_length"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Длина земляной части (км)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="earthen_part_length"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Длина облицованной части (км)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="coated_part_length"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Подвешенная орошаемая площадь (Га)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="irrigated_area"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Примечание</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="note"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="16">
          <v-select
            :items="passport_serviceorganization"
            v-model="serviceorganizations"
            item-text="name"
            item-value="id"
            placeholder="Обслуживающая организация"
            outlined
            dense
            hide-details
            class="mb-1"
            clearable
            multiple
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Адм. районы</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
        </v-col>
      </v-row>


      <div class="mt-2">
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">
              <v-btn type="button" class="font-weight-bold" @click="saveClick"
              >Сохранить</v-btn
              >
            </v-card-text>
          </v-col>
          <v-col v-if="isDraw" cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
            ><v-btn type="button" class="font-weight-bold" @click="clearData"
            >Очистить</v-btn
            ></v-card-text
            >
          </v-col>
          <v-col v-else cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
            ><v-btn
              type="button"
              class="font-weight-bold"
              @click="deleteClick"
            >Удалить</v-btn
            ></v-card-text
            >
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-card>
</template>

<script>
import Vue from "vue";
import { URL_API } from "@/settings/apiConnect";

export default {
  name: "ChannelPassport",

  props: {
    saveWater: Function,
    deleteWater: Function,
    properties: Object,
    isDraw: Boolean
  },

  data() {
    return {
      plantingDateMenu: false,
      passport_channeltype: [],
      passport_serviceorganization: [],
      passport_belonging: [],
      passport_strengtheningtype: [],
      passport_landscaping: [],

      channel_type_id: null,
      channel_id: null,
      checkpoint: null,
      name: null,
      water_source: null,
      purpose: null,
      strengthening_type_id: null,
      landscaping_id: null,
      valves: null,
      shandors: null,
      bridges: null,
      duckers: null,
      belonging_id: null,
      exploitation_year: null,
      bandwidth: null,
      total_length: null,
      earthen_part_length: null,
      coated_part_length: null,
      irrigated_area: null,
      note: null,
      strengtheningtype: null,
      serviceorganizations: [],
    };
  },

  methods: {
    saveClick() {
      const data = {
        channel_type_id: this.channel_type_id,
        channel_id: this.channel_id,
        checkpoint: this.checkpoint,
        name: this.name,
        water_source: this.water_source,
        purpose: this.purpose,
        strengthening_type_id: this.strengthening_type_id,
        landscaping_id: this.landscaping_id,
        valves: this.valves,
        shandors: this.shandors,
        bridges: this.bridges,
        duckers: this.duckers,
        belonging_id: this.belonging_id,
        exploitation_year: this.exploitation_year,
        bandwidth: this.bandwidth,
        total_length: this.total_length,
        earthen_part_length: this.earthen_part_length,
        coated_part_length: this.coated_part_length,
        irrigated_area: this.irrigated_area,
        note: this.note,
        serviceorganizations: this.serviceorganizations
      };

      this.saveWater(data);
    },
    deleteClick() {
      this.deleteWater();
    },
    clearData() {
      this.channel_type_id = null;
      this.channel_id = null;
      this.checkpoint = null;
      this.name = null;
      this.water_source = null;
      this.purpose = null;
      this.strengthening_type_id = null;
      this.landscaping_id = null;
      this.valves = null;
      this.shandors = null;
      this.bridges = null;
      this.duckers = null;
      this.belonging_id = null;
      this.exploitation_year = null;
      this.bandwidth = null;
      this.total_length = null;
      this.earthen_part_length = null;
      this.coated_part_length = null;
      this.irrigated_area = null;
      this.note = null;
      this.serviceorganizations = [];
    },

  },

  async mounted() {
    let response = await Vue.axios.get(`${URL_API}channeltype`);
    this.passport_channeltype = response.data;
    let response2 = await Vue.axios.get(`${URL_API}serviceorganization`);
    this.passport_serviceorganization = response2.data;
    let response3 = await Vue.axios.get(`${URL_API}belonging`);
    this.passport_belonging = response3.data;
    let response4 = await Vue.axios.get(`${URL_API}strengtheningtype`);
    this.passport_strengtheningtype = response4.data;
    let response5 = await Vue.axios.get(`${URL_API}landscaping`);
    this.passport_landscaping = response5.data;

    if (this.properties?.id) {
      response = await Vue.axios.get(
        `${URL_API}channel/view/${this.properties.id}`
      );
      const data = response.data;
      console.log("данные воды", data);
      this.channel_type_id = data.channel_type_id;
      this.channel_id = data.channel_id;
      this.checkpoint = data.checkpoint;
      this.name = data.name;
      this.water_source = data.water_source;
      this.purpose = data.purpose;
      this.strengthening_type_id = data.strengthening_type_id;
      this.landscaping_id = data.landscaping_id;
      this.valves = data.valves;
      this.shandors = data.shandors;
      this.bridges = data.bridges;
      this.duckers = data.duckers;
      this.belonging_id = data.belonging_id;
      this.exploitation_year = data.exploitation_year;
      this.bandwidth = data.bandwidth;
      this.total_length = data.total_length;
      this.earthen_part_length = data.earthen_part_length;
      this.coated_part_length = data.coated_part_length;
      this.irrigated_area = data.irrigated_area;
      this.note = data.note;
      this.strengtheningtype = data.strengtheningtype;
      this.serviceorganizations = data.serviceorganizations;
    }
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
