<template>
  <v-card width="350">
    <v-col align="center">
      <h3 class="mt-5 mb-2" align="center">Паспорт озера</h3>

      <v-row v-if="properties">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">ID объекта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ properties[0] }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="16">
          <v-select
            :items="passport_serviceorganization"
            v-model="serviceorganizations"
            item-text="name"
            item-value="id"
            placeholder="Обслуживающая организация"
            outlined
            dense
            hide-details
            class="mb-1"
            clearable
            multiple
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Название озера</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="name"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Наличие проточности</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="presence_of_flow"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Впадающие реки</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="inflowing_rivers"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Вытекающие реки</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="outflowing_rivers"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Широта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ latitude }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Долгота</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ longitude }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Площадь водосбора (км2)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="cachment_area"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Площадь зеркала (км2)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="surface_area"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Длина береговой линии (км)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="shoreline_length"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Длина</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="length"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Ширина</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="width"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Средняя глубина (м)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="average_depth"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Максимальная глубина (м)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="maximum_depth"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Водная масса (тысяч м3)</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="water_mass"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Адм. район</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ district_name }}</v-card-text>
        </v-col>
      </v-row>

      <div class="mt-2">
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">
              <v-btn type="button" class="font-weight-bold" @click="saveClick"
              >Сохранить</v-btn
              >
            </v-card-text>
          </v-col>
          <v-col v-if="isDraw" cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
            ><v-btn type="button" class="font-weight-bold" @click="clearData"
            >Очистить</v-btn
            ></v-card-text
            >
          </v-col>
          <v-col v-else cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
            ><v-btn
              type="button"
              class="font-weight-bold"
              @click="deleteClick"
            >Удалить</v-btn
            ></v-card-text
            >
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-card>
</template>

<script>
import Vue from "vue";
import { URL_API } from "@/settings/apiConnect";

export default {
  name: "PondPassport",

  props: {
    saveWater: Function,
    deleteWater: Function,
    properties: Object,
    isDraw: Boolean
  },

  data() {
    return {
      plantingDateMenu: false,
      passport_pondtype: [],
      passport_serviceorganization: [],
      passport_belonging: [],

      name: null,
      presence_of_flow: null,
      inflowing_rivers: null,
      outflowing_rivers: null,
      latitude: null,
      longitude: null,
      cachment_area: null,
      surface_area: null,
      shoreline_length: null,
      length: null,
      width: null,
      average_depth: null,
      maximum_depth: null,
      water_mass: null,
      district_id: null,
      serviceorganizations: [],

      district_name: null,
    };
  },

  methods: {
    saveClick() {
      const data = {
        name: this.name,
        presence_of_flow: this.presence_of_flow,
        inflowing_rivers: this.inflowing_rivers,
        outflowing_rivers: this.outflowing_rivers,
        latitude: this.latitude,
        longitude: this.longitude,
        cachment_area: this.cachment_area,
        surface_area: this.surface_area,
        shoreline_length: this.shoreline_length,
        length: this.length,
        width: this.width,
        average_depth: this.average_depth,
        maximum_depth: this.maximum_depth,
        water_mass: this.water_mass,
        district_id: this.district_id,
        serviceorganizations: this.serviceorganizations
      };

      this.saveWater(data);
    },
    deleteClick() {
      this.deleteWater();
    },
    clearData() {
      this.name = null;
      this.presence_of_flow = null;
      this.inflowing_rivers = null;
      this.outflowing_rivers = null;
      this.latitude = null;
      this.longitude = null;
      this.catchment_area = null;
      this.surface_area = null;
      this.shoreline_length = null;
      this.length = null;
      this.width = null;
      this.average_depth = null;
      this.maximum_depth = null;
      this.water_mass = null;
      this.district_id = null;
      this.serviceorganizations = [];
    },

  },

  async mounted() {
    let response = await Vue.axios.get(`${URL_API}serviceorganization`);
    this.passport_serviceorganization = response.data;

    if (this.properties && this.properties[0]) {
      response = await Vue.axios.get(
        `${URL_API}lake/view/${this.properties[0]}`
      );
      const data = response.data;
      console.log("данные воды", data);
      this.name = data.name;
      this.presence_of_flow = data.presence_of_flow;
      this.inflowing_rivers = data.inflowing_rivers;
      this.outflowing_rivers = data.outflowing_rivers;
      this.latitude = data.latitude;
      this.longitude = data.longitude;
      this.cachment_area = data.cachment_area;
      this.surface_area = data.surface_area;
      this.shoreline_length = data.shoreline_length;
      this.length = data.length;
      this.width = data.width;
      this.average_depth = data.average_depth;
      this.maximum_depth = data.maximum_depth;
      this.water_mass = data.water_mass;
      this.district_id = data.district_id;
      this.serviceorganizations = data.serviceorganizations;

      if (this.district_id) {
        this.district_name = data.district.name;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
