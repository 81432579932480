<template>
  <v-card width="350">
    <v-col align="center">
      <h3 class="mt-5 mb-2" align="center">Паспорт точки сбора анализов воды</h3>

      <v-row v-if="properties">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">ID объекта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ properties.id }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Дата обследования</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu v-model="plantingDateMenu">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_of_examination"
                v-bind="attrs"
                v-on="on"
                hide-details
                clearable
              ></v-text-field>
            </template>
            <v-date-picker min="1960-01-01" max="2025-01-01" v-model="date_of_examination" scrollable></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Год проведения последнего обследования</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu v-model="plantingDateMenu2">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="year_of_last_examination"
                v-bind="attrs"
                v-on="on"
                hide-details
                clearable
              ></v-text-field>
            </template>
            <v-date-picker-years min="1960" max="2024" v-model="year_of_last_examination" scrollable></v-date-picker-years>
          </v-menu>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">pH</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="ph"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Кальций</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="calcium"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Магний</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="magnesium"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Сухой остаток</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="dry_residue"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Натрий</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="sodium"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Хлориды</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="chlorides"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Сульфаты</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="sulfates"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Гидрокарбонаты</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="hydrocarbonates"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Азот аммоний</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="ammonium_nitrogen"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Нитриты</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="nitrites"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Нитраты</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="nitrates"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Железо общее</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="total_iron"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Железо общее</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="total_iron"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Фтор</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="fluorine"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Кремний</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="silicon"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Бром</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="bromine"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Йод</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="iodine"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Жесткость общая</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="general_hardness"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Карбонаты</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="carbonates"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Формула курлова</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="kurlov_formula"
            type="number"
            step="0.001"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Примечание</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="note"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="mt-2">
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">
              <v-btn type="button" class="font-weight-bold" @click="saveClick"
              >Сохранить</v-btn
              >
            </v-card-text>
          </v-col>
          <v-col v-if="isDraw" cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
            ><v-btn type="button" class="font-weight-bold" @click="clearData"
            >Очистить</v-btn
            ></v-card-text
            >
          </v-col>
          <v-col v-else cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
            ><v-btn
              type="button"
              class="font-weight-bold"
              @click="deleteClick"
            >Удалить</v-btn
            ></v-card-text
            >
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-card>
</template>

<script>
import Vue from "vue";
import { URL_API } from "@/settings/apiConnect";

export default {
  name: "WateranalysispointPassport",

  props: {
    saveWater: Function,
    deleteWater: Function,
    properties: Object,
    isDraw: Boolean
  },

  data() {
    return {
      plantingDateMenu: false,
      plantingDateMenu2: false,

      date_of_examination: null,
      year_of_last_examination: null,
      ph: null,
      calcium: null,
      magnesium: null,
      dry_residue: null,
      sodium: null,
      chlorides: null,
      sulfates: null,
      hydrocarbonates: null,
      ammonium_nitrogen: null,
      nitrites: null,
      nitrates: null,
      total_iron: null,
      fluorine: null,
      silicon: null,
      bromine: null,
      iodine: null,
      general_hardness: null,
      carbonates: null,
      kurlov_formula: null,
      note: null,
    };
  },

  methods: {
    saveClick() {
      const data = {
        date_of_examination: this.date_of_examination,
        year_of_last_examination: this.year_of_last_examination,
        ph: this.ph,
        calcium: this.calcium,
        magnesium: this.magnesium,
        dry_residue: this.dry_residue,
        sodium: this.sodium,
        chlorides: this.chlorides,
        sulfates: this.sulfates,
        hydrocarbonates: this.hydrocarbonates,
        ammonium_nitrogen: this.ammonium_nitrogen,
        nitrites: this.nitrites,
        nitrates: this.nitrates,
        total_iron: this.total_iron,
        fluorine: this.fluorine,
        silicon: this.silicon,
        bromine: this.bromine,
        iodine: this.iodine,
        general_hardness: this.general_hardness,
        carbonates: this.carbonates,
        kurlov_formula: this.kurlov_formula,
        note: this.note
      };

      this.saveWater(data);
    },
    deleteClick() {
      this.deleteWater();
    },
    clearData() {
      this.date_of_examination = null;
      this.year_of_last_examination = null;
      this.ph = null;
      this.calcium = null;
      this.magnesium = null;
      this.dry_residue = null;
      this.sodium = null;
      this.chlorides = null;
      this.sulfates = null;
      this.hydrocarbonates = null;
      this.ammonium_nitrogen = null;
      this.nitrites = null;
      this.nitrates = null;
      this.total_iron = null;
      this.fluorine = null;
      this.silicon = null;
      this.bromine = null;
      this.iodine = null;
      this.general_hardness = null;
      this.carbonates = null;
      this.kurlov_formula = null;
      this.note = null;
    },

  },

  async mounted() {
    let response;

    if (this.properties?.id) {
      response = await Vue.axios.get(
        `${URL_API}wateranalysispoint/view/${this.properties.id}`
      );
      const data = response.data;
      console.log("данные воды", data);
      this.date_of_examination = data.date_of_examination;
      this.year_of_last_examination = data.year_of_last_examination;
      this.ph = data.ph;
      this.calcium = data.calcium;
      this.magnesium = data.magnesium;
      this.dry_residue = data.dry_residue;
      this.sodium = data.sodium;
      this.chlorides = data.chlorides;
      this.sulfates = data.sulfates;
      this.hydrocarbonates = data.hydrocarbonates;
      this.ammonium_nitrogen = data.ammonium_nitrogen;
      this.nitrites = data.nitrites;
      this.nitrates = data.nitrates;
      this.total_iron = data.total_iron;
      this.fluorine = data.fluorine;
      this.silicon = data.silicon;
      this.bromine = data.bromine;
      this.iodine = data.iodine;
      this.general_hardness = data.general_hardness;
      this.carbonates = data.carbonates;
      this.kurlov_formula = data.kurlov_formula;
      this.note = data.note;
    }
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
