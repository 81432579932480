<template>
  <v-card width="350">
    <v-col align="center">
      <h3 class="mt-5 mb-2" align="center">Паспорт точки</h3>

      <v-row v-if="properties">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">ID объекта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ properties.id }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Широта</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ latitude }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Долгота</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ longitude }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Наименование</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              class="text-end"
              v-model="name"
              outlined
              dense
              hide-details
              clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Тип точки</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
              :items="passport_pointtype"
              v-model="point_type_id"
              item-text="name"
              item-value="id"
              placeholder="Тип точки"
              outlined
              dense
              hide-details
              class="mb-1"
              clearable
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Адм. район</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ district_name }}</v-card-text>
        </v-col>
      </v-row>

      <div class="mt-2">
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">
              <v-btn type="button" class="font-weight-bold" @click="saveClick"
              >Сохранить</v-btn
              >
            </v-card-text>
          </v-col>
          <v-col v-if="isDraw" cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
            ><v-btn type="button" class="font-weight-bold" @click="clearData"
            >Очистить</v-btn
            ></v-card-text
            >
          </v-col>
          <v-col v-else cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
            ><v-btn
                type="button"
                class="font-weight-bold"
                @click="deleteClick"
            >Удалить</v-btn
            ></v-card-text
            >
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-card>
</template>

<script>
import Vue from "vue";
import { URL_API } from "@/settings/apiConnect";

export default {
  name: "PointPassport",

  props: {
    saveWater: Function,
    deleteWater: Function,
    properties: Object,
    isDraw: Boolean
  },

  data() {
    return {
      plantingDateMenu: false,
      passport_pointtype: [],

      latitude: null,
      longitude: null,
      name: null,
      point_type_id: null,
      district_id: null,

      district_name: null,
    };
  },

  methods: {
    saveClick() {
      const data = {
        latitude: this.latitude,
        longitude: this.longitude,
        name: this.name,
        point_type_id: this.point_type_id,
        district_id: this.district_id
      };

      this.saveWater(data);
    },
    deleteClick() {
      this.deleteWater();
    },
    clearData() {
      this.latitude = null;
      this.longitude = null;
      this.name = null;
      this.point_type_id = null;
      this.district_id = null;
    },

  },

  async mounted() {
    let response = await Vue.axios.get(`${URL_API}pointtype`);
    this.passport_pointtype = response.data;

    if (this.properties?.id) {
      response = await Vue.axios.get(
          `${URL_API}point/view/${this.properties.id}`
      );
      const data = response.data;
      console.log("данные воды", data);
      this.latitude = data.latitude;
      this.longitude = data.longitude;
      this.name = data.name;
      this.point_type_id = data.point_type_id;
      this.district_id = data.district_id;

      if (this.district_id) {
        this.district_name = data.district.name;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
